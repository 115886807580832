import React from "react";
import { PostIndexPageProps } from "../lib";
import PageLayout from "./PageLayout";

export default ({
  posts,
  allTags,
  tagSizes,
  selectedTags,
  selectTag,
  deselectTag,
  setSelectedTags,
}: PostIndexPageProps) => (
  <PageLayout>
    <main>
      <section aria-label="Tags">
        <h1>Blog Post Tags</h1>
        <h2>Check to filter:</h2>
        {allTags.map((tag) => (
          <label key={tag}>
            <input
              type="checkbox"
              checked={selectedTags.includes(tag)}
              key={tag}
              name={tag}
              onChange={(e) =>
                selectedTags.includes(tag) ? deselectTag(tag) : selectTag(tag)
              }
            />
            {tag} ({tagSizes[tag]})&nbsp;&nbsp;
          </label>
        ))}
      </section>
      <section aria-label="Content">
        <h2 aria-label="Post Count">{posts.length} Posts:</h2>
        <ul>
          {posts.map(({ title, date, slug, excerpt, tags, image }) => (
            <li aria-label="Post" key={slug}>
              <a aria-role="link" href={slug}>
                <span aria-label="Date">{date}</span>:{" "}
                <span aria-label="Title">{title}</span>
              </a>
              <p>
                <b>Tags:</b> <i>{tags.join(", ")}</i>
              </p>
              <p>
                <b>Excerpt:</b> <i>{excerpt}</i>
              </p>
            </li>
          ))}
        </ul>
      </section>
    </main>
  </PageLayout>
);
