import React, { useState, useMemo, ReactNode } from "react";
import { graphql } from "gatsby"; // to query for image data
import PostIndexPage from "../components/PostIndexPage";
import { FluidObject } from "gatsby-image";
import { countBy, get, flatten, uniq, intersection } from "lodash";
import Img from "gatsby-image"; // to take image data and render it
import {
  PostIndexPageProps,
  AllPostsSchema,
  PostSchema,
  PostIndexPost,
} from "../lib/";
import { DefaultGatsbyImage } from "../components/DefaultGatsbyImage";

export const containsSelectedTags = (post, selectedTags) =>
  selectedTags.length ? intersection(selectedTags, post.tags).length > 0 : true;

export const transformPost = ({
  slug,
  image,
  date,
  title,
  excerpt,
  tags,
  imageFit,
}: PostSchema): PostIndexPost => {
  const imageSrc = get(image, "childImageSharp.fixed");
  return {
    slug,
    date,
    title,
    excerpt,
    image: imageSrc ? (
      <Img
        imgStyle={{ objectFit: imageFit }}
        style={{ width: "100%", height: "100%" }}
        fixed={imageSrc}
      />
    ) : (
      <DefaultGatsbyImage
        style={{ width: "100%", height: "100%" }}
        seed={title}
        imgType="fixed"
      />
    ),
    tags: (tags || []).sort(),
  };
};

export const usePostsState = (
  { allPosts }: AllPostsSchema,
  transformer = transformPost
): Omit<PostIndexPageProps, "location"> => {
  const [selectedTags, setSelectedTags] = useState([]);
  return useMemo(
    (): Omit<PostIndexPageProps, "location"> => ({
      totalPosts: allPosts.nodes.length,
      posts: allPosts.nodes
        .filter((post) => containsSelectedTags(post, selectedTags))
        .map(transformer),
      allTags: uniq(
        flatten(
          allPosts.nodes
            .map((node) => node.tags)
            .sort((a, b) => a.length - b.length)
        )
      ),
      tagSizes: countBy(flatten(allPosts.nodes.map((node) => node.tags))),
      selectedTags,
      selectTag: (tag) => setSelectedTags(uniq([...selectedTags, tag])),
      deselectTag: (tag) =>
        setSelectedTags(selectedTags.filter((c) => c !== tag)),
      setSelectedTags,
    }),
    [allPosts, selectedTags, transformer]
  );
};

export const query = graphql`
  query {
    allPosts(
      sort: { order: DESC, fields: date }
      filter: { depricated: { ne: true } }
    ) {
      nodes {
        excerpt
        date
        slug
        title
        tags
        imageFit
        image {
          childImageSharp {
            fixed(width: 300) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default ({ data, location }) => (
  <PostIndexPage location={location} {...usePostsState(data)} />
);
